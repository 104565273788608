import React from "react";
import theme from "theme";
import { Theme, Em, Text, Section, Button, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Wheel Wizards
			</title>
			<meta name={"description"} content={"Там, де кожна поїздка – ідеальна поїздка"} />
			<meta property={"og:title"} content={"Wheel Wizards"} />
			<meta property={"og:description"} content={"Там, де кожна поїздка – ідеальна поїздка"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
		</Components.Header>
		<Components.Header2 />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66795e813f24780021f8255f/images/1-1.jpg?v=2024-06-25T07:45:48.830Z) center center/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						as="h1"
						font="--headline1"
						md-font="--headline2"
						margin="10px 0"
						color="--light"
						border-color="--color-light"
					>
						<Em>
							Ласкаво просимо до майстерні з ремонту велосипедів Wheel Wizards
						</Em>
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" background="--color-light">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				У Wheel Wizards ми віримо, що кожен велосипед заслуговує найкращого догляду, а кожен водій заслуговує на безпечну та плавну поїздку.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Незалежно від того, чи подорожуєте ви щодня, воїн у вихідні чи змагаєтесь на велосипеді, наша команда експертів тут, щоб переконатися, що ваш велосипед завжди у відмінному стані. Ми пишаємося нашим доброзичливим обслуговуванням, професійним ремонтом і прагненням повернути вас на дорогу з упевненістю.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Наші послуги
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 1.5rem 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Про нас
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Велосипедна майстерня Wheel Wizards — це єдине місце для ремонту та обслуговування велосипедів. Завдяки багаторічному досвіду та пристрасті до велоспорту наша команда кваліфікованих техніків прагне надавати виняткове обслуговування всіх типів велосипедів.{" "}
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Від незначних налаштувань до капітального ремонту ми виконуємо все це з точністю та дбайливістю. Наша найсучасніша майстерня оснащена найновішими інструментами та технологіями для діагностики та вирішення будь-якої проблеми, яка може виникнути у вашого велосипеда. Ми прагнемо досконалості та прагнемо перевершити ваші очікування кожного разу, коли ви відвідуєте.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07:45:48.834Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Експерти-техніки
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Наша команда складається з висококваліфікованих і досвідчених техніків, які захоплюються велосипедами. Вони стежать за останніми тенденціями та технологіями ремонту велосипедів, щоб забезпечити найвищий рівень обслуговування.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07:45:48.830Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-3.jpg?v=2024-06-25T07%3A45%3A48.830Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Якісні деталі
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Ми використовуємо лише високоякісні деталі та аксесуари для ремонту, щоб гарантувати довговічність і ефективність вашого велосипеда.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07:45:48.821Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-2.jpg?v=2024-06-25T07%3A45%3A48.821Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Швидке виконання
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Ми розуміємо важливість вашого велосипеда у вашому повсякденному житті. Ось чому ми пропонуємо швидке та ефективне обслуговування, щоб якомога швидше повернути вас на дорогу.
					</Text>
				</Box>
			</Box>
			<Text margin="0px 0px 2rem 0px" font="--headline3" color="--darkL1">
				Від ремонту спущених шин до комплексного ремонту трансмісії, ми пропонуємо широкий спектр послуг, щоб задовольнити всі ваші потреби в обслуговуванні велосипедів.
			</Text>
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--darkL1">
				Нашим пріоритетом є ваше задоволення. Ми прислухаємося до ваших занепокоєнь і пропонуємо індивідуальні рішення, щоб ви були задоволені нашими послугами.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Дізнатися більше
			</Button>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 2rem 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							У Wheel Wizards ми прагнемо не лише забезпечувати першокласний ремонт велосипедів, але й сприяти екологічності та екологічній відповідальності. Велосипед є одним із найбільш екологічних видів транспорту, і ми прагнемо підвищити його переваги, впроваджуючи екологічно чисті практики в нашому магазині. Від переробки використаних деталей і матеріалів до використання екологічно чистих мастил і очисників, ми вживаємо заходів, щоб мінімізувати наш вплив на навколишнє середовище. Вибираючи Wheel Wizards, ви не тільки отримуєте винятковий сервіс, але й підтримуєте бізнес, який піклується про планету.
						</Text>
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Em font="--headline3">
								Ми віримо в силу спільноти та важливість віддачі. Wheel Wizards активно бере участь у місцевих заходах, благодійних атракціонах і громадських семінарах, щоб популяризувати велосипед і здоровий спосіб життя. У нашому магазині регулярно проводяться семінари з обслуговування велосипедів, щоб навчити водіїв доглядати за своїми велосипедами, забезпечуючи безпечніші та приємніші поїздки для всіх. Ми також співпрацюємо з місцевими школами та організаціями, щоб заохочувати велосипед як здоровий та екологічний спосіб пересування.
							</Em>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});